import React, { Fragment, useEffect, useState } from "react";
import Main from "../components/main";
import SEO from "../components/section/seo";
import ProfileTOTP from "../controls/totp";

export default function MFA() {
  return (
  <Main >
    <SEO title="Shalom College - Portal" />
    <ProfileTOTP apiUrl={process.env.BACKEND_URL}/>
  </Main>)
}



